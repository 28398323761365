

































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import page from '@/mixins/page';
import { FormData, FormModel } from '@/models/form/FormModel';
import { PermissionModel, PermissionModelDefault } from '@/models/system/PermissionModel';
import { getPermissionPage, removePermission } from '@/api/system/PermissionApi';
import AuthAdd from '@/views/system/auth-add.vue';
import AuthBindResource from '@/views/system/auth-bind-resource.vue';
import AuthDetail from '@/views/system/auth-detail.vue';

@Component({
  name: 'AuthList',
  components: {
    AuthDetail,
    AuthBindResource,
    AuthAdd
  }
})
export default class Flow extends mixins(page) {
  formListData: PermissionModel[] = [];
  loadingOuter: boolean = false;
  currForm: FormModel = FormData;
  formSearchModel: any = { name: '' };
  permissionId: string = '';

  // 是否显示新增/编辑角色弹窗
  visible: boolean = false;

  // 绑定资源界面是否显示
  bindVisible: boolean = false;

  // 权限详情
  permissionInfo: PermissionModel = PermissionModelDefault;

  // 详情是否显示
  infoVisible: boolean = false;

  // 加载数据
  mounted() {
    this.getFormPage();
  }

  // 获取表单列表
  getFormPage(): void {
    let params = {
      name: this.formSearchModel.name,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.loadingOuter = true;
    getPermissionPage(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.loadingOuter = false;
        this.formListData = res.data.list;
        this.total = res.data.total;
      }
    });
  }

  // 点击查询
  handleSearch() {
    this.pageIndex = 1;
    this.getFormPage();
  }

  // 点击重置
  handleSearchReset() {
    this.pageIndex = 1;
    this.formSearchModel = { name: '' };
    this.getFormPage();
  }

  // 返回上一页
  back() {
    this.$router.go(-1);
  }

  // 新增表单
  addForm() {
    this.currForm = FormData;
    this.visible = true;
  }

  // 打开绑定资源界面
  bindResource(row) {
    this.permissionId = row.id;
    this.bindVisible = true;
  }

  // 打开详情界面
  detail(row) {
    this.permissionInfo = row;
    this.infoVisible = true;
  }

  // 删除权限
  remove(row) {
    removePermission({ id: row.id }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getFormPage();
      }
    });
  }

  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getFormPage();
  }

  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getFormPage();
  }
}
