export interface PermissionModel {
  tenantId?: string;
  code: string;
  name: string;
  published: boolean;
}

export const PermissionModelDefault: PermissionModel = {
  tenantId: '1',
  code: '',
  name: '',
  published: true
};
