

































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { PermissionModel } from '@/models/system/PermissionModel';

@Component({
  name: 'PermissionForm'
})
export default class PermissionForm extends Vue {
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;

  // 权限id
  @Prop({ default: '' }) private permissionInfo!: PermissionModel;

  // ref
  @Ref() readonly formRef;

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
