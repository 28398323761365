var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.$t("lang_authority_details"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.permissionInfo,
            "label-suffix": "：",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_name"), prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: {
                          placeholder: _vm.$t(
                            "lang_please_enter_the_permission_name"
                          ),
                          disabled: true,
                        },
                        model: {
                          value: _vm.permissionInfo.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.permissionInfo, "name", $$v)
                          },
                          expression: "permissionInfo.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_code"), prop: "code" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: {
                          placeholder: _vm.$t(
                            "lang_please_enter_permission_code"
                          ),
                          disabled: true,
                        },
                        model: {
                          value: _vm.permissionInfo.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.permissionInfo, "code", $$v)
                          },
                          expression: "permissionInfo.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }