

































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { PermissionModel, PermissionModelDefault } from '@/models/system/PermissionModel';
import { addPermission } from '@/api/system/PermissionApi';

@Component({
  name: 'PermissionForm'
})
export default class PermissionForm extends Vue {
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;

  // 权限id
  @Prop({ default: '' }) private permissionId!: string;

  // ref
  @Ref() readonly formRef;

  // 权限新增实体
  private permissionData: PermissionModel = PermissionModelDefault;

  // 表单加载状态
  private formLoading: boolean = false;

  // 表单验证规则
  private formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_fill_in_permission_name'),
      trigger: 'blur'
    }
  };

  // 监听是否显示和隐藏
  @Watch('visible')
  visibleWatcher(val) {
    // 清空表单内容
    this.permissionData = PermissionModelDefault;

    // 清理表单验证
    this.formRef.resetFields();
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 保存表单
  saveForm() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      let result = addPermission(this.permissionData);
      result
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success', res.data);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
